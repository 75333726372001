





















































































import { Component, Vue, Watch } from "vue-property-decorator";

interface dataBanner {
  id: number;
  imagen: string;
  imagen_mobile: string;
  link: string;
}

@Component({
  components: {},
})
export default class Videos extends Vue {
  private nombreWeb: any = "";
  private videos: any = [];
  private currentPage = 1;
  private total = 0;
  private slide = 1;
  private ultimasNoticias: any = [];
  private slideIndex: number = 1;

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 9)) {
      this.getVideos();
    }
  }

  @Watch("$refs")
  private refsWatch(newValue: any) {}

  private mounted() {
    this.getVideos();
  }

  private getVideos() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("videos/general?pagina=" + this.currentPage)
      .then((res) => {
        // console.log(res.data);
        this.videos = res.data.videos;
        this.total = res.data.total;

        for (let i = 0; i < this.videos.length; i++) {
          let youtubeUrl = this.videos[i].url;
          let videoId = this.extractVideoId(youtubeUrl);
          this.videos[
            i
          ].url_iframe = `https://www.youtube.com/embed/${videoId}`;
        }

        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private extractVideoId(url) {
    const match = url.match(
      /(?:\?v=|&v=|youtu\.be\/|\/embed\/|\/v\/|\/\d{1,2}\/|\/embed\?v=)([^#&?]{11})/
    );
    return match && match[1];
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "ene";
    } else if (mesInicio == 2) {
      mesInicio = "feb";
    } else if (mesInicio == 3) {
      mesInicio = "mar";
    } else if (mesInicio == 4) {
      mesInicio = "abr";
    } else if (mesInicio == 5) {
      mesInicio = "may";
    } else if (mesInicio == 6) {
      mesInicio = "jun";
    } else if (mesInicio == 7) {
      mesInicio = "jul";
    } else if (mesInicio == 8) {
      mesInicio = "ago";
    } else if (mesInicio == 9) {
      mesInicio = "sep";
    } else if (mesInicio == 10) {
      mesInicio = "oct";
    } else if (mesInicio == 11) {
      mesInicio = "nov";
    } else {
      mesInicio = "dic";
    }

    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + ", " + anhoInicio;
    }
  }


  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }
}
